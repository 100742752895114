import React from "react"
import { Link } from "gatsby"
import { FooterBarConfig } from "../config/config-data"
import "../styles/footerbar.css"


const FooterBar = ({ CustomHeight, CustomText, ModalState }) => {

  const [ Show, setShow ] = React.useState(false)
  
  React.useEffect(() => {
    window.addEventListener("scroll", listenToScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, [])

  const listenToScroll = () => {
    if (window.scrollY > (CustomHeight || window.innerHeight)) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  
  return (
    <div id="footerbar_1662795305864" className={Show ? "Footer_Bar Footer_Bar__Show" : "Footer_Bar"}>
      {
      FooterBarConfig.button_label
      ?
      FooterBarConfig.button_url_link
      ?
      <a id="footerbar_1662795310551" href={FooterBarConfig.button_url_link} aria-label="Map Location" target={button_url_new_tab && "_blank"} rel="noopener noreferrer"><button id="footerbar_1662795308688" className="Floating">{FooterBarConfig.button_label}</button></a>
      :
      FooterBarConfig.button_page_link
      ?
      <Link id="footerbar_1662795313920" exact="true" to={FooterBarConfig.button_page_link}><button id="footerbar_1662795312518" className="Floating">{FooterBarConfig.button_label}</button></Link>
      :
      <button id="footerbar_1662795316095" type="button" className="Floating" onClick={ModalState}>{FooterBarConfig.button_label}</button>
      :
      ''
      }
      {CustomText ?
      <div id="footerbar_1662795317534" className="Normal_Text">{CustomText}</div>
      :
      <div id="footerbar_1662795319383" className="Normal_Text">{FooterBarConfig.footer_bar_text}</div>
      }
    </div>
  );
}

export default FooterBar
